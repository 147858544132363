<template>
  <div>
    <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
		  <!-- <el-form-item label="门店">
		    <el-select v-model="search.shop_type" clearable class="w100">
		      <el-option
		        v-for="item in options.shop_type"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item> -->
		  <el-form-item label="来源类型">
		    <el-select v-model="search.source_type" clearable class="w100">
		      <el-option
		        v-for="item in options.source_type"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item>



        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" @click="taskFrame = true">添加广告费</el-button>
        <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>

    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            max-height="550"
            border
            v-loading="loading"
            :cell-style="cellStyle"
            :header-cell-style="rowClass"
          >
            <ElTableColumn label="市场" prop="market_name" fixed />
            <ElTableColumn label="线上目标" prop="shop_task" />
            <ElTableColumn label="线上预约" prop="all_yuyue_num" />
            <ElTableColumn label="进店个数" prop="all_into_num" />
            <ElTableColumn label="目标完成率" prop="completion_rate" />
            <ElTableColumn label="疤痕进店" prop="all_bh_num" />
            <ElTableColumn label="痘痘进店" prop="all_dd_num" />
            <ElTableColumn label="项目进店" prop="all_xm_num" />
            <ElTableColumn label="成交个数" prop="all_new_order_num" />
            <ElTableColumn label="总成交额" prop="all_order_money" />
            <ElTableColumn label="新单成交额" prop="all_new_order_money" />
            <ElTableColumn label="进店率" prop="store_entry_rate" />
            <ElTableColumn label="成交率" prop="turnover_rate" />
            <ElTableColumn label="总广告费" prop="advertising_expenses" />
            <ElTableColumn label="回访广告" prop="advertising_expenses_huifang" />
            <ElTableColumn label="团购广告" prop="advertising_expenses_tuangou" />
            <ElTableColumn label="新单ROI" prop="monthROI" />
            <ElTableColumn label="整体ROI" prop="allROI" />

          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>
    </div>
    <!-- 新增弹框 -->
    <el-dialog title="新增月度门店广告费用" :visible.sync="taskFrame"
    :close-on-click-modal="false" width="30%" @close="closeTask">
      <el-form
        ref="taskDetail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="taskDetail"
        class="frame"
        size="mini"
      >
        <el-form-item label="门店名称:" prop="shop_market_id">
          <el-select
              v-model="taskDetail.shop_market_id"
              clearable
              remote
              filterable
              class="w300"
              placeholder="请输入门店名称"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
        </el-form-item>

        <el-form-item label="广告类型:" prop="shop_market_type">
          <el-select
              v-model="taskDetail.shop_market_type"
              clearable
              remote
              filterable
              class="w300"
              placeholder="请选择广告类型"
            >
              <el-option
                v-for="item in options.shop_market_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
        </el-form-item>

        <el-form-item label="年/月" prop="moneth">
          <el-date-picker
            v-model="time"
            type="month"
            class="w300"
            value-format="yyyy-MM"
            @change="changeTime"
            placeholder="选择月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="广告费用" prop="advertising_expenses">
          <el-input v-model="taskDetail.advertising_expenses" class="w300" placeholder="请输入广告费用">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <div class="footerBtn mt50">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('taskDetail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { readAdminMarketList,getShopList } from "@/api/shop";
import { moneth_mk_task,monethShop,monethMarket } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  source_type =0;
  shop_type=1;
}
class TaskDetail {
  shop_market_id = ""; // 店铺id
  shop_market_type = ""; // 广告费类型 1 回访、2团购
  year = ''; // 年份
  moneth = ''; // 月份
  advertising_expenses = ''; // 当月广告费用，两位小数点的正数
}
class Rules {
 shop_market_id = [
    { required: true, message: "请选择门店", trigger: "change" }
  ];
  shop_market_type = [
    { required: true, message: "请选择类型", trigger: "change" }
  ];
 year = [
    { required: true, message: "请输选择年份", trigger: "change" }
  ];
 moneth = [
    { required: true, message: "请输选择月份", trigger: "change" }
  ];
 advertising_expenses = [
    {
      pattern: /^([-])?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    },{ required: true, message: "请输输入广告费用", trigger: "blur" }
  ];
}
class Options {
  shop_market_id = [];
  shop_id = [];

  shop_market_type = [
    { id:1, name: "回访" },
    { id:2, name: "团购" },
  ];

  source_type = [
    { id: 0, name: "全部" },
    { id: 7, name: "美团" },
	{ id: 1, name: "高德" },
    { id: 2, name: "回访" },
  	// { id: 3, name: "百度(医)" },
  	// { id: 4, name: "美团(医)" },
  	// { id: 5, name: "抖音(医)" }
    { id: 6, name: "抖团" }
  ];

  shop_type = [
    { id: 1, name: "门店" },
    { id: 2, name: "医院" }
  ];
}
export default {
  name: 'OrderList',
  components: {
    // pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
       taskDetail: new TaskDetail(), //
       rules: new Rules(), // 校验
       options: new Options(), //选项
      page: new Page(), // 分页
      list: [], // 数据展示
      loading: false,
      taskFrame: false,
      time: '',
      exportS: false,
    }
  },

  mounted () {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
     getList(option) {
      // readAdminMarketList({ page: 1, limit: 100000 }).then(res => {
      //   this.options.shop_market_id = res.list;
      // });

      getShopList({ page: 1, limit: 100000 }).then(res => {
        // console.log(res);
        this.options.shop_id = res.list;
      });

       this.loading = true
       if (option === "search") this.page = new Page();
      monethMarket({ ...this.search,export:0 }).then(res => {
        this.list = res.list;
        this.loading = false
      });
    },
    // 选择时间
    changeTime(e){
      var index = e.lastIndexOf("-");
      this.taskDetail.year = e.substring(0, index);
      this.taskDetail.moneth = e.substring(index+1,e.length);
    },
    // 关闭弹窗
    closeTask(){
      this.taskDetail = new TaskDetail()
      this.time = ''
    },
     submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加该市场月度任务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm(){
      moneth_mk_task({ ...this.taskDetail }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.taskFrame = false;
        this.getList();
      });
    },
    // 导出
    exportExcel() {
      this.exportS = true
      monethMarket({...this.search,export:1}).then(res => {
        window.open(res.url);
        this.exportS = false
      });
    },
    cellStyle({row, column, rowIndex, columnIndex}){
        if(columnIndex <= 1){
            return 'background:#A8EDF1;text-align:center'
        }
        if(columnIndex > 1 && columnIndex <= 7){
            return 'background:#FDE9D9;text-align:center'
        }
        if(columnIndex > 7 && columnIndex <= 12){
            return 'background:#D8E4BC;text-align:center'
        }
        if(columnIndex > 12 && columnIndex <= 15){
            return 'background:#FFCC99;text-align:center'
        }
        if(columnIndex > 15){
            return 'background:#FFFF99;text-align:center'
        }
        
        return '';
      },
    rowClass({row, column, rowIndex, columnIndex}){
      return 'background:#E9EDEE;text-align:center'

    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
    }

    
  }
}
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}
.footerBtn,.btnBox {
  text-align: right;
}
</style>
